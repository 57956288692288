export const colorRange = [
  { label: 'Significant Growth', val: 'significant_growth', color: '#3F6C51' },
  { label: 'Modest Growth', val: 'modest_growth', color: '#95C1A6' },
  { label: 'No Change', val: 'no_change', color: '#E3C567' },
  { label: 'Modest Loss', val: 'modest_loss', color: '#DA7F7F' },
  { label: 'Significant Loss', val: 'significant_loss', color: '#B60000' }
];

export const ActionMessages = {
  NEW: 'Design & Run your own Experiments to predict the effectiveness of the underlying action.',
  ACTIVE: 'This action was started without experiment.',
  COMPLETED: 'This action was completed without experiment.',
  ABORTED: 'This action was aborted without experiment.'
};

export const defaultColor = '#CCCCCC';

export const KPIDomainIcons = {
  'Home Equity': 'home_twotone',
  'Home Lending': 'home',
  'Credit Card': 'credit_card',
  'Auto Lending': 'directions_car',
  'Business Lending': 'business',
  'Student Lending': 'school',
  Mortgage: 'real_estate_agent',
  'Personal Lending': 'face',
  'Multi-Domain': 'group_work'
};
export const SuperAdmin = 'LH-Super-Admin';

export const unitList = [
  { key: '1', label: '$', value: '$' },
  { key: '2', label: '#', value: '#' },
  { key: '3', label: '%', value: '%' },
  { key: '4', label: 'None', value: '' }
];

export const precisionList = [
  { key: 'precision-0', label: '0', value: 0 },
  { key: 'precision-1', label: '1', value: 1 },
  { key: 'precision-2', label: '2', value: 2 },
  { key: 'precision-3', label: '3', value: 3 },
  { key: 'precision-4', label: '4', value: 4 },
  { key: 'precision-5', label: '5', value: 5 },
  { key: 'precision-6', label: '6', value: 6 },
  { key: 'precision-7', label: '7', value: 7 },
  { key: 'precision-8', label: '8', value: 8 },
  { key: 'precision-9', label: '9', value: 9 }
];

export const settingTabs = {
  businessday: {
    id: 1,
    label: 'Business Day',
    value: 'business-day'
  },
  metric: {
    id: 2,
    label: 'Metrics Management',
    value: 'metrics-management'
  },
  cohort: {
    id: 3,
    label: 'Cohort Management',
    value: 'cohort-management'
  },
  dataCatalog: {
    id: 4,
    label: 'Data Catalog Management',
    value: 'data-catalog-management'
  },
  parameter: {
    id: 5,
    label: 'Parameter Management',
    value: 'parameter-management'
  }
};

export const operatorList = [
  {
    id: 1,
    label: 'abs',
    value: 'abs',
    description: 'abs(x)'
  },
  {
    id: 2,
    label: 'pow',
    value: 'pow',
    description: 'pow(x,n)'
  },
  {
    id: 3,
    label: 'max',
    value: 'max',
    description: 'max(x1,x2,x3,...,xn)'
  },
  {
    id: 4,
    label: 'min',
    value: 'min',
    description: 'min(x1,x2,x3,...,xn)'
  },
  {
    id: 5,
    label: 'average',
    value: 'average',
    description: 'average(x1,x2,x3,...,xn)'
  },

  {
    id: 6,
    label: 'weighted_mean',
    value: 'weighted_mean',
    description: 'weighted_mean((x1,y1),(x2,y2),...,(xn,yn))'
  }
];

export const designCCTabs = [
  {
    id: 1,
    label: 'Highlights',
    value: 'highlights'
  },
  {
    id: 2,
    label: 'Major Disrupters',
    value: 'major-disrupters'
  }
];

export const permissionAuthorities = {
  Write: 'write',
  Read: 'read',
  Delete: 'delete'
};

export const permissionFeatures = {
  KpiTree: 'KpiTree',
  DataCatalogManagement: 'DataCatalogManagement',
  KpiConfiguration: 'KpiConfiguration',
  CohortManagement: 'CohortManagement',
  MetricManagement: 'MetricManagement',
  KpiTreePublish: 'KpiTreePublish',
  ParameterManagement: 'ParameterManagement',
  CohortExportRequest: 'CohortExportRequest'
};

export const insightsColors = {
  KPI: '#071B7A',
  COHORT: '#BD0088',
  ANOMALY: '#5C1A4B',
  FORECASTING: '#A44821',
  EXPERIMENT: '#00bd2f',
  CLUSTER: '#00bd87',
  KDA: '#365C84'
};

export const tSize = ['Small', 'Medium', 'Large'];
export const defaultNodeSize = 'Small';
export const nodeSize = {
  Small: {
    width: 250,
    xVertical: -120,
    charLength: 11,
    siblings: 3.5,
    nonSiblings: 3.5,
    xHorizontal: 400
  },
  // "S" : {
  // 	width:300,
  // 	xVertical: -144,
  // 	charLength: 17,
  // 	siblings: 3.8,
  // 	nonSiblings: 3.8,
  // 	xHorizontal: 450,
  // },
  Medium: {
    width: 350,
    xVertical: -170,
    charLength: 25,
    siblings: 4,
    nonSiblings: 4,
    xHorizontal: 500
  },
  // "L" : {
  // 	width:400,
  // 	xVertical: -195,
  // 	charLength: 32,
  // 	siblings: 4.2,
  // 	nonSiblings: 4.2,
  // 	xHorizontal: 550,
  // },
  Large: {
    width: 450,
    xVertical: -220,
    charLength: 39,
    siblings: 4.5,
    nonSiblings: 4.5,
    xHorizontal: 600
  }
};

export const groupNameMapping = (groupListArray, groupName) =>
  groupListArray
    .filter((item) => `assetClassHome` in item)[0]
    .children.find((item) => item.route === groupName);

export const regexValidation = {
  thresholdSetting: /^[0-9.-~<>=% ]*$/
};
