import ApiRequest from './ApiService';
import { API_ROOT } from '../config';

class KpiService {
  // cohorts
  getAllCohorts() {
    return ApiRequest.get(`${API_ROOT}listKpiCohort`);
  }
  createCohorts(cohorts) {
    return ApiRequest.post(`${API_ROOT}kpiCohort/`, cohorts);
  }
  updateCohorts(cohorts) {
    return ApiRequest.put(`${API_ROOT}kpiCohort/`, cohorts);
  }
  deleteCohorts(cohortId) {
    return ApiRequest.patch(`${API_ROOT}kpiCohort/`, cohortId);
  }

  // metrics
  getAllMetrics() {
    return ApiRequest.get(`${API_ROOT}listKpiMetrics`);
  }
  createMetrics(metrics) {
    return ApiRequest.post(`${API_ROOT}kpiMetrics/`, metrics);
  }
  updateMetrics(metrics) {
    return ApiRequest.put(`${API_ROOT}kpiMetrics/`, metrics);
  }
  deleteMetrics(metricId) {
    return ApiRequest.patch(`${API_ROOT}kpiMetrics/`, metricId);
  }

  // parameters - LC-723 Parameterized SQL Queries
  getAllParameters() {
    return ApiRequest.get(`${API_ROOT}listParameters`);
  }
  getParameterById(parameterId) {
    return ApiRequest.post(`${API_ROOT}parameterById/`, parameterId);
  }
  createParameters(parameters) {
    return ApiRequest.post(`${API_ROOT}parameter/`, parameters);
  }
  updateParameters(parameters) {
    return ApiRequest.put(`${API_ROOT}parameter/`, parameters);
  }
  deleteParameters(parameterId) {
    return ApiRequest.patch(`${API_ROOT}parameter/`, parameterId);
  }

  // KPI tree
  listPublishedTrees(data) {
    return ApiRequest.post(`${API_ROOT}listKpiHomeTree/`, data);
  }

  listSavedTrees(data) {
    return ApiRequest.post(`${API_ROOT}kpiTrees/`, data);
  }

  getTreeNodes(treeId) {
    return ApiRequest.get(`${API_ROOT}kpiTree/${treeId}`);
  }

  setTreeData(data, isNew) {
    if (isNew) {
      return ApiRequest.post(`${API_ROOT}kpiTree`, data);
    } else {
      return ApiRequest.put(`${API_ROOT}kpiTree`, data);
    }
  }

  saveTreeSettings(data, isNew) {
    if (isNew) {
      return ApiRequest.post(`${API_ROOT}kpiTreeSettings`, data);
    } else {
      return ApiRequest.put(`${API_ROOT}kpiTreeSettings`, data);
    }
  }

  getTreeSettings(treeId){
    return ApiRequest.get(`${API_ROOT}kpiTreeSettings/${treeId}`)
  }

  saveTreeStructure(payload){
    return ApiRequest.put(`${API_ROOT}kpiTreeStructure`,payload)
  }

  publishTree(kpiTreeId){
    return ApiRequest.patch(`${API_ROOT}kpiTree/${kpiTreeId}/publish`)
  }

  getListPublishItems(){
    return ApiRequest.get(`${API_ROOT}globalConfig/key/PUBLISH_ITEMS`)
  }

  deleteTreeData(treeId) {
    return ApiRequest.delete(`${API_ROOT}kpiTree/${treeId}`);
  }

  getCalculateTreeData(treeId) {
    return ApiRequest.get(`${API_ROOT}kpiTreeNodes/${treeId}`);
  }

  setCalculateTreeData(data) {
    return ApiRequest.post(`${API_ROOT}kpiTreeNodes/`, data);
  }

  getTreeNodeCohorts(data) {
    return ApiRequest.post(`${API_ROOT}cohortOutput/`, data);
  }

  getCalculatedTreeList() {
    return ApiRequest.get(`${API_ROOT}listCalculatedTree`);
  }

  getFilteredAnalysis(data) {
    return ApiRequest.post(`${API_ROOT}displayCalculatedTree/`, data);
  }

  getKPITreeFilter(treeID) {
    return ApiRequest.get(`${API_ROOT}listAggDataFilter/${treeID}`);
  }

  getAnamoliesData(data) {
    return ApiRequest.post(`${API_ROOT}cohorteChart/`, data);
  }

  getADChart(data) {
    return ApiRequest.post(`${API_ROOT}ad-chart/`, data);
  }

  listCohortDriven() {
    return ApiRequest.get(`${API_ROOT}cohortDriven`);
  }

  getFiltersForKPI() {
    return ApiRequest.get(`${API_ROOT}treeFilterAll`);
  }

  getSequence() {
    return ApiRequest.get(`${API_ROOT}getSequence`);
  }

  // by App provider
  getKPIsByDomain(path) {
    return ApiRequest.get(API_ROOT + path);
  }

  getAssetClassCount(assetClass) {
    return ApiRequest.get(
      `${API_ROOT}kpiTree/domainCount?domain=${assetClass}`
    );
  }

  // Groups List
  getAllGroups() {
    return ApiRequest.get(`${API_ROOT}kpiGroupNames`);
  }

  // Templates List
  getAllTemplates() {
    return ApiRequest.get(`${API_ROOT}templateNames`);
  }

  // Connections List
  getAllConnections() {
    return ApiRequest.get(`${API_ROOT}connectionNames`);
  }

  // Trigger Aggregation Job
  setAggregationData(treeId, data) {
    //LC-1621
    return ApiRequest.post(`${API_ROOT}kpiTree/${treeId}/trigger_aggregation`, data);
  }

  // Clone Tree API
  cloneKPITree(treeId) {
    return ApiRequest.get(`${API_ROOT}kpiTree/clone?kpiTreeId=${treeId}`);
  }

  //KPI Tree Document
  getKPIDocument(treeId) {
    return ApiRequest.get(`${API_ROOT}kpi-tree-document?treeId=${treeId}`);
  }

  getAnamoliesMetricsName(treeID) {
    return ApiRequest.get(`${API_ROOT}agg-tree-metrics-info?kpiTreeId=${treeID}`);
  }

  getConvertWaterFallViewData(payload){
    return ApiRequest.post(`${API_ROOT}convert-to-waterfall`, payload);
  }
}

export default new KpiService();
