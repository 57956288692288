import { ACCESS_GROUPS } from '../config';
import moment from 'moment';
import {
  Icon,
} from '@mui/material';
import { SuperAdmin, permissionAuthorities } from './AllTables';

class Utils {
  getStoredValue(key) {
    try {
      const item = window.sessionStorage.getItem(key);
      return JSON.parse(item);
    } catch (error) {
      console.error(error);
    }
  }

  getLoginInUserName = () => {
    return (
      this.getStoredValue('resp')?.response?.name?.charAt(0).toUpperCase() +
      this.getStoredValue('resp')?.response?.name?.slice(1)
    );
  };

  setStoredValue(key, value) {
    try {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log(error);
    }
  }

  authToken() {
    let userInfo = this.getStoredValue('resp');
    if (userInfo && `response` in userInfo) {
      return userInfo.response['token'];
    }
    return null;
  }

  getUserRights() {
    let GROUPS = ACCESS_GROUPS || {};
    let userInfo = this.getStoredValue('resp');
    if (!userInfo || !userInfo.response) return [];
    const memberOf = userInfo.response.memberOf;
    let hasRights = Object.keys(GROUPS).filter((group) => {
      return GROUPS[group].some((grp) => memberOf.includes(grp));
    });
    return hasRights;
  }

  soiDomainBased() {
    let result = 0;
    let userInfo = this.getStoredValue('resp');
    if (userInfo && `response` in userInfo) {
      let theme = userInfo.response['userProfile']['theme'];
      result = this.isJsonString(theme)['ISSOIDomainBased'];
    }
    return result;
  }

  getUserId() {
    let userInfo = this.getStoredValue('resp');
    if (userInfo && `response` in userInfo) {
      return userInfo.response['userProfile']['userId'];
    }
  }

  isJsonString(str) {
    if (typeof str !== 'string') {
      return str;
    }
    try {
      return JSON.parse(str);
    } catch (error) {
      return [];
    }
  }

  shortInt(value, precisionValue = 2) {
    let isNegative = value < 0;  
    let n = Math.abs(value).toFixed(precisionValue);
    let lastValue = '';
    if (n < 1e3) lastValue += n.toString();
    if (n >= 1e3 && n < 1e6) lastValue += +(n / 1e3).toFixed(precisionValue) + 'K';
    if (n >= 1e6 && n < 1e9) lastValue += +(n / 1e6).toFixed(precisionValue) + 'M';
    if (n >= 1e9 && n < 1e12) lastValue += +(n / 1e9).toFixed(precisionValue) + 'B';
    if (n >= 1e12) lastValue += +(n / 1e12).toFixed(precisionValue) + 'T';

    return isNegative ? `-${lastValue}` : lastValue;
}

shortIntWithZero(value, precisionValue = 2, isUseOfKMB = true) {
  let isNegative = value < 0;
  let n = Math.abs(value);
  let suffix = '';
  let scaledValue = n;

  if (n < 1e3) {
      suffix = '';
  } else if (n < 1e6) {
      scaledValue = n / 1e3;
      suffix = 'K';
  } else if (n < 1e9) {
      scaledValue = n / 1e6;
      suffix = 'M';
  } else if (n < 1e12) {
      scaledValue = n / 1e9;
      suffix = 'B';
  } else {
      scaledValue = n / 1e12;
      suffix = 'T';
  }

  let formattedValue = scaledValue.toFixed(precisionValue);

  // Remove unnecessary trailing zeros after decimal point
  formattedValue = parseFloat(formattedValue).toFixed(precisionValue);

  return (isNegative ? '-' : '') + formattedValue + (isUseOfKMB ? suffix : '');
}

  trunc(str, count) {
    let dots = String(str).length > count ? '...' : '';
    return String(str).substring(0, count) + dots;
  }

  getNodeStatus(status) {
    const nodeStatusColors = {
      significant_growth: '#3f6c51',
      modest_growth: '#95c1a6',
      no_change: '#bfcad8',
      modest_loss: '#da7f7f',
      significant_loss: '#b60000',
      l: '#3f6c51',
      m: '#e3c567',
      h: '#b60000',
      default: '#CCCCCC'
    };

    return nodeStatusColors[status] || nodeStatusColors.default;
  }

  getOption = (data) => {
    let chartStr = data
      ? JSON.parse(data)
      : {
          xAxis: { data: [] },
          series: [{ data: [], markLine: { data: [{ xAxis: '' }] } }]
        };
    return {
      xAxis: {
        show: false,
        type: 'category',
        data: chartStr.xAxis?.data
      },
      yAxis: { show: false },
      tooltip: { show: true },
      grid: { show: false, left: 0, right: 0, top: '15%', bottom: '10%' },
      series: chartStr.series.map((item, _i) => ({
        data: item.data,
        name: item.name,
        type: 'line',
        smooth: true,
        showSymbol: false,
        itemStyle: {
          normal: {
            lineStyle: {
              width: 1.5,
              color: this.getStoredValue('isDarkMode') ? '#fff' : '#365C84'
            }
          }
        }
      }))
    };
  };

  toFixed = (x) => {
    if (Math.abs(x) < 1.0) {
      let e = parseInt(x.toString().split('e-')[1]);
      if (e) {
          x *= Math.pow(10,e-1);
          x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
      }
    } else {
      let e = parseInt(x.toString().split('+')[1]);
      if (e > 20) {
          e -= 20;
          x /= Math.pow(10,e);
          x += (new Array(e+1)).join('0');
      }
    }
    return x;
  }

  // this function used to show truncate Number, it will not roundoff the number
  // e.g 7.99998989, decimal: 2
  // output: 7.99
  toFixedTrunc = (x, n) => {
    x = this.toFixed(x) 
    const v = (typeof x === 'string' ? x : x.toString()).split('.');
    if (n <= 0) return v[0];
    let f = v[1] || '';
    if (f.length > n) return `${v[0]}.${f.substr(0,n)}`;
    while (f.length < n) f += '0';
    return `${v[0]}.${f}`
  }

  fixPrecision(data, value = 2) {
    return data.map((item) => {
      return this.toFixedTrunc(item, value)
    });
  }

  showDate = (dateString) => {
    const currentDate = new Date();
    const inputDate = new Date(this.convertShortDate(dateString));

    // Set the time part of both dates to midnight to compare only the date portion.
    currentDate.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);

    const oneDayInMillis = 24 * 60 * 60 * 1000; // Milliseconds in a day

    if (currentDate - inputDate === 0) {
      return 'Today';
    } else if (currentDate - inputDate === oneDayInMillis) {
      return 'Yesterday';
    } else {
      return this.convertShortDate(dateString);
    }
  };

  getGlobalDecimalPrecision = () => this.getStoredValue('global')?.DECIMAL_PRECISION ?? 2;

  convertShortDate = (date = new Date()) =>
    moment.utc(date).format('MM/DD/YYYY').toString();
  convertLongDate = (date = new Date()) =>
    moment.utc(date).format('MM/DD/YYYY hh:mm A').toString();
  //convertShortDate = (date = new Date()) =>
  //moment.utc(date).format(this.getStoredValue('global')?.DATE_FORMAT_SHORT ?? 'YYYY/DD/MM').toString();
  //convertLongDate = (date = new Date()) =>
  //moment.utc(date).format(this.getStoredValue('global')?.DATE_FORMAT_LONG ?? 'MM/DD/YYYY hh:mm A').toString();

  seriesObject(item, dottedLineIndex, mini, metric) {
    return {
      type: 'line',
      showSymbol: item.name === 'KPI' ? true : false,
      symbol: 'circle',
      symbolSize: 5,
      smooth: true,
      name: item.name === 'KPI' ? metric.metricName : item.name,
      data:
        item.name !== 'KPI'
          ? this.fixPrecision(item.data, metric.decimalPrecision)
          : dottedLineIndex
            ? this.fixPrecision(item.data, metric.decimalPrecision).map((value, index) =>
                index > dottedLineIndex ? '' : value
              )
            : this.fixPrecision(item.data, metric.decimalPrecision),
      lineStyle:
        item.name !== 'KPI'
          ? {
              color: metric.metricPolarity=='Positive'? 
                item.name === 'High Acceptance Range' ? '#238823' : '#CC0000':
                item.name === 'High Acceptance Range' ? '#CC0000' : '#238823',
              type: 'dashed',
              width: 1
            }
          : null,
      markPoint: item.markPoint
        ? {
            symbolSize: mini === true ? 10 : 30,
            data: item.markPoint.map((point) => {
              return {
                xAxis: point.xAxis,
                yAxis: point.yAxis,
                symbol: 'circle',
                symbolSize: mini === true ? 8 : 10,
                itemStyle: {
                  color: '#CC0000'
                }
              };
            })
          }
        : null
    };
  }

  getOptionPMF(
    forecastingDate,
    xAxis,
    series,
    title,
    mini,
    isDashBoard,
    isDarkMode,
    chartType,
    metricValues
  ) {
    const metric = isDashBoard
      ? {
          metricName: metricValues ? metricValues: '',
          decimalPrecision: 2
        }
      : metricValues;

    let dottedLineIndex = null;
    xAxis.map((value, index) => {
      if (value == forecastingDate) {
        dottedLineIndex = index;
      }
    });

    let seriesObj = series.map((item) =>
      this.seriesObject(item, dottedLineIndex, mini, metric)
    );

    // forecasting logic
    const kpiItem = series.filter((item) => item.name === 'KPI')[0];

    const kpiOpject = this.seriesObject(
      kpiItem,
      dottedLineIndex,
      mini,
      metric
    );

    if (kpiItem && dottedLineIndex) {
      const dashedSeries = {
        ...kpiOpject,
        name: 'Primary Metric Forecasting',
        data: this.fixPrecision(kpiItem.data, metric.decimalPrecision).map((value, index) =>
          index < dottedLineIndex ? '' : value
        ),
        lineStyle: {
          color: '#065196',
          type: 'dashed',
          width: 1.5
        },
        silent: true,
        markArea: {
          itemStyle: {
            color: '#E4E4E4'
          },
          data: [
            [
              {
                xAxis: forecastingDate
              },
              {
                xAxis: xAxis[xAxis.length-1]
              }
            ]
          ]
        }
      };

      const currentDateLine = {
        ...kpiOpject,
        name: 'Forecasting Date',
        data: xAxis.map((value) => forecastingDate),
        type: 'line',
        markLine: {
          symbol: 'none',
          label: {
            show: false
          },
          data: [
            {
              xAxis: `${forecastingDate}` // Set the y-coordinate of the line (0 in this case)
            }
          ],
          lineStyle: {
            type: 'solid',
            width: 2
          }
        }
      };
      seriesObj = [...seriesObj, currentDateLine, dashedSeries];
    }

    const option = {
      title: {
        text: title
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: isDarkMode ? '#121212' : '#fafafa'
      },
      legend: {
        top: mini ? 2 : 25,

        padding: [5, 5, 0, 0],
        textStyle: {
          fontSize: mini ? 8 : 12
        }
      },
      toolbox: {
        show: mini === true ? false : true,
        feature: {
          magicType: {
            type: ['line', 'bar'],
            title: { line: 'Line', bar: 'Bar' }
          },
          saveAsImage: { title: 'Save Image' }
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxis
      },
      yAxis: {
        type: 'value',
        axisLabel: !isDashBoard
          ? {
              interval: 0,
              formatter: (value) => this.yaxisFormatter(value, chartType, metric.decimalPrecision)
            }
          : { interval: 0 },
        boundaryGap: [0, '100%'],
        // min: function (value) {
        //   return Math.floor(value.min - value.min * 0.02);
        // },
        // max: function (value) {
        //   return Math.ceil(value.max + value.max * 0.1);
        // },
        scale: true,
        axisLine: { onZero: false }
      },
      series: seriesObj,
      dataZoom:
        mini === true
          ? [
              {
                id: 'dataZoomX',
                type: 'slider',
                xAxisIndex: [0],
                filterMode: 'filter',
                handleSize: '70%',
                textStyle: {
                  fontSize: 6
                },
                handleIcon:
                  'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z' //
              },
              {
                id: 'dataZoomY',
                type: 'slider',
                yAxisIndex: [0],
                // show: false,
                start: 0,
                end: isDashBoard ? 95 : 100,
                filterMode: 'filter',
                handleSize: '70%',
                textStyle: {
                  fontSize: 6
                },
                handleIcon:
                  'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z'
              }
            ]
          : [
              {
                id: 'dataZoomX',
                type: 'slider',
                xAxisIndex: [0],
                filterMode: 'filter',
                handleSize: '80%',
                textStyle: {
                  fontSize: 8
                },
                handleIcon:
                  'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z' //
              },
              {
                id: 'dataZoomY',
                type: 'slider',
                yAxisIndex: [0],
                // show: false,
                start: 0,
                end: isDashBoard ? 95 : 100,
                filterMode: 'filter',
                handleSize: '70%',
                textStyle: {
                  fontSize: 6
                },
                handleIcon:
                  'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z'
              }
            ],
      color: ['#065196', '#238823', '#CC0000', '#1890ff', '#065196']
    };

    return option;
  }

  yaxisFormatter(value, chartType, precision = 0) {
    if (chartType === '$') {
      let newVal = this.shortInt(value);
      return `${newVal} ${chartType}`;
    }
    return `${value.toFixed(precision)} ${chartType}`;
  }

  getPermission(feature) {
    let userInfo = this.getStoredValue('resp');
    if (!userInfo || !userInfo.response) return [];
    const response = userInfo.response;
    const memberOf = userInfo.response.memberOf;
    if (memberOf.includes(SuperAdmin)) {
      const { Read, Write, Delete } = permissionAuthorities;
      return [Read, Write, Delete];
    } else if (response && `permissions` in response) {
      if (!response.permissions || !response.permissions[feature]) return [];
      const featureData = response.permissions[feature];
      return featureData ? featureData?.authorities : [];
    }
    return [];
  }

  tableHeader = (
    cohortSelected,
    cohortHeader,
    selectedGroup = undefined,
    key = undefined,
    cohort_type = undefined
  ) => {

    let isCohortType = cohortHeader.every((x) => x.cohortType);
    if (cohortSelected !== 'Entity') {
      cohortHeader = isCohortType
        ? cohortHeader.filter((x) => x.cohortType.includes(cohortSelected))
        : cohortHeader;
    }
    if (cohortSelected === 'Entity') {
      if (selectedGroup !== 'node') {
        cohortHeader = isCohortType
          ? cohortHeader.filter((x) => x.cohortType.includes(key))
          : cohortHeader;
      } else {
        cohortHeader = isCohortType
          ? cohortHeader.filter((x) => x.cohortType.includes(cohort_type))
          : cohortHeader;
      }
    }
  
    return cohortHeader.map((item, idx) => {
      let {
        title,
        dataIndex,
        key,
        setGraphic,
        setSort,
        decimalValue,
        setPostValueSymbol,
        setPreValueSymbol,
        ccb
      } = item;
      let isNumber = false;
      let sorter = false;
  
      let render = (txt) => {
        isNumber = !isNaN(txt) || !isNaN(parseFloat(txt?.replace(/,/g, '')));
        txt = txt && decimalValue ? txt.toFixed(decimalValue) : txt;
        return txt || txt === 0
          ? `${setPreValueSymbol || ''}${txt}${setPostValueSymbol || ''}`
          : 'N/A';
      };
  
      if (setGraphic) {
        render = (txt) => (
          <Icon
            className={
              txt === 'G'
                ? 'green-text'
                : txt === 'A'
                  ? 'amber-text'
                  : txt === 'R'
                    ? 'red-text'
                    : 'text-light'
            }
          >
            fiber_manual_record
          </Icon>
        );
      }
  
      if (dataIndex === 'cohortId' || dataIndex === 'segments') {
        render = item.render;
      }
  
      if (setSort && ccb) {
        render = (value) =>
          parseFloat(value.toFixed(decimalValue)).toLocaleString();
        sorter = (a, b) => a[item.dataIndex] - b[item.dataIndex];
      } else if (setSort) {
        sorter = (a, b) =>
          isNumber
            ? a[item.dataIndex] - b[item.dataIndex]
            : a[item.dataIndex].localeCompare(b[item.dataIndex]);
      }
  
      if (title.includes('_')) {
        let str = title.split('_');
        title = (
          <span>
            {str[0]}
            <br />
            <small className='text-muted'>{str[1]}</small>
          </span>
        );
      } else {
        title = <span>{title}</span>;
      }
  
      return {
        title,
        dataIndex,
        key,
        sorter,
        render,
        width: idx === 0 ? 270 : 'auto'
      };
    });
  };

  manageSuccesResponse = (response) => {
    let modifiedResponse = response;
    if ('data' in response) {
      if ('code' in response.data) {
        const code = response.data.code < 204 ? 200 : response.data.code;
        modifiedResponse = {
          ...response,
          data: {
            ...response.data,
            code: code
          }
        };
      }
    }

    return modifiedResponse;
  };

  manageErrorResponse = (error) => {
    let modifiedErrorResponse = error;
    modifiedErrorResponse = {
      message: error?.response?.status===403 ? error?.response?.data?.message : modifiedErrorResponse.message
    }
    return modifiedErrorResponse;
  };
}

export default new Utils();
