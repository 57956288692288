import React from 'react';
import MenuService from '../../services/menuService';
import { PROJECT_ID, URL } from '../../config';
import utilities from '../../utilities';

const MenuContext = React.createContext();

export const MenuProvider = ({ children }) => {
  const [routes, setRoutes] = React.useState([]);
  const [filePath, setFilePath] = React.useState([]);

  const readJson = async () => {
    let params = [];
    await MenuService.appMenu()
      .then((response) => {
        response = utilities.manageSuccesResponse(response);
        // console.log(response, "responseresponseresponseresponseresponseresponse")
        if (response.status === 200) {
          response.data['response'].forEach((item) => {
            item.path = item.path.replace('configpages/','');
            if (item.containParam) {
              params.push({
                ...item,
                hide: true,
                route: item.route + '/:' + item.params
              });
            }
            if (item.isHome) {
              params.push({ ...item, hide: true, route: '' });
            }
          });

          setRoutes([...response.data['response'], ...params]);
        }
      })
      .catch((error) => {
        error = utilities.manageErrorResponse(error);
        console.log(error);
      })
      .finally(() => readFilePath());
  };

  const readFilePath = async () => {
    await fetch(URL + 'path.json')
      .then((res) => res.json())
      .then((data) => {
        data = utilities.manageSuccesResponse(data);
        setFilePath([...data[PROJECT_ID]]);
      });
  };

  React.useEffect(() => {
    readJson();
  }, []);

  return (
    <MenuContext.Provider value={{ routes, setRoutes, filePath }}>
      {children}
    </MenuContext.Provider>
  );
};

export { MenuContext };
