import { Alert, Snackbar } from '@mui/material';
import React from 'react';

const ErrorSnackbar = () => {
    const closeSnackbar = () => {
        let errorSnackbar = document.querySelector('.err-snackbar');
        errorSnackbar && errorSnackbar.classList.add('d-none');
    }
  return (
    <Snackbar
      className='err-snackbar d-none'
      open={true}
      transitionDuration={10}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      onClose={closeSnackbar}
    >
      <Alert
        onClose={closeSnackbar}
        variant='filled'
        severity='error'
        sx={{ width: '100%' }}
      >
        Access is denied!
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnackbar;
