const appConfig = window.globalConfig;

//assign value to variables
export const PROJECT_ID = appConfig?.projectId;
export const URL = appConfig?.url;
export const API_ROOT = appConfig?.apiRoot;
export const DASHBOARD_API_ROOT = appConfig?.dashboardApiRoot;
export const PYTHON_API_ROOT = appConfig?.pythonapiRoot;
export const AIRFLOW_URL = appConfig?.airflowUrl;
export const ENV_NAME = appConfig?.envName;
export const CHATBOT_VERSION = appConfig?.chatBotVersion;
export const ACCESS_GROUPS = appConfig?.LDAPGroups;
export const CONTEXT = appConfig?.contextpath;
export const SSO = appConfig?.SSO;
export const SSOLogin = appConfig?.SSOLogin;
export const DEEP_ANALYSIS_URL = appConfig?.Deep_Analysis;
export const HEADER_DOMAIN_BASED = appConfig?.HeaderDomainBased; //SI-262 SOI Header Configuration
export const SUMMARY_INSIGHTS = appConfig?.summaryInsights;
export const INSIGHTS_ACTION = appConfig?.insightsAction;
export const ACTION_EXPERIMENTS = appConfig?.actionExperiments;
export const COHORT_PREVIEW_EXPORT_DATA = appConfig?.cohortPreviewAndExportData;
export const KEY_DRIVER_ANALYSIS = appConfig?.keyDriverAnalysis;
export const KEY_DRIVER_ANALYSIS_LIST = appConfig?.keyDriverAnalysisList ?? [];
export const CONTEXT_NAME = appConfig?.contextName;

export const SSO_URL_GOOGLE = appConfig?.SSO_URL_GOOGLE;
export const SSO_URL_ADFS = appConfig?.SSO_URL_ADFS;
export const SSO_URL_OTHER = appConfig?.SSO_URL_OTHER;
export const ADVISOR_DASHBOARD_URL1 = appConfig?.advisor_dashboard1;
export const ADVISOR_DASHBOARD_URL2 = appConfig?.advisor_dashboard2;
export const ADVISOR_DASHBOARD_URL3 = appConfig?.advisor_dashboard3;
export const ADVISOR_DASHBOARD_REQ = appConfig?.advisor_dashboard_required;
export const STATICMRM = appConfig?.StaticMRM;
export const STATICRISK360 = appConfig?.staticRisk360;
export const WEBSOCKETURL = appConfig?.websocketUrl;
export const HeaderAcceptType = appConfig?.HeaderAcceptType;
export const LOGO = appConfig?.LOGO;
export const COHORT_HEADER = appConfig?.cohort_header;
export const GATEWAYBASED = appConfig?.GatewayBased;
export const GITCLIENTID = appConfig?.GITCLIENTID;
export const PSP = appConfig?.PSP;
export const TableHeader = appConfig?.TableHeader;
export const TRIGGER_AGGREGATION_ON_PUBLISH =
  appConfig?.TriggerAggregationOnPublish; //LC-1621
export const TRIGGER_AGGREGATION_EXCLUSION_LIST =
  appConfig?.TriggerAggregationExclusionList; //LC-1621
export const CONTACT_EMAIL = appConfig?.ContactEmail;
export const PROMPTLIBRARYLINK = appConfig?.promptLibraryLink;
export const IDLE_TIMER = appConfig?.idleTimer;
export const REFRESHTOKENTIMER = appConfig?.refreshTokenTimer;

export const aws = {
  bucketName: 'datalake-dev',
  dirName: '',
  region: '',
  accessKeyId: '',
  secretAccessKey: ''
};
export const globalData = {
  websocketUrl: WEBSOCKETURL ? WEBSOCKETURL : 'wss://idsp.incedolabs.com:3073',
  websocket: null
};

export const CLUSTER_ANALYZER = appConfig?.clusterAnalyzer;
