import React, { useEffect, useState, useRef } from 'react';
import {
  API_ROOT,
  PROJECT_ID,
  ENV_NAME,
  SSO_URL_GOOGLE,
  SSO_URL_OTHER,
  SSO_URL_ADFS,
  SSOLogin,
  URL,
  CONTEXT_NAME,
  CHATBOT_VERSION
} from '../config';
import {
  TextField,
  InputAdornment,
  Button,
  Icon,
  IconButton,
  CircularProgress
} from '@mui/material';
import { notification } from 'antd';
import { loginUser, registerUser } from '../services/authService';
import Utils from '../utilities';
import { getBrainSparkUserComm } from '../services/brainSparkService';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSnackBar } from '../hooks';
import cohortPreviewExportService from '../services/cohortPreviewExportService';

const LoginModal = (props) => {
  const recaptcha = useRef();

  const [username, setUsername] = useState('');
  const [userPwd, setUserPwd] = useState('');
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [appVersion, setAppVersion] = useState('1.0.0');
  const [captchaKey, setCaptchaKey] = useState('UNKNOWN');

  const [isLogin, setLogin] = useState(true);

  useEffect(() => {
    if (!Utils.getStoredValue('clb')) {
      openNotification();
    }
    setVersion();
    updateCaptchaKey();
    if (!errors.length) ssoVerifyLogin();
  }, []);

  const setVersion = async () => {
    let ver = await Utils.getStoredValue('version');
    if (ver !== 'undefined') setAppVersion(ver);
  };

  const updateCaptchaKey = async () => {
    let ver = await Utils.getStoredValue('captchaSettingKey');
    if (ver !== 'undefined') {
      setCaptchaKey(ver);
    }
  };

  const postChatBotMsg = () => {
    getBrainSparkUserComm({
      message: 'version',
      sender: `${Utils.getLoginInUserName()};${Utils.getUserId()};${CONTEXT_NAME}`
    })
      .then((resp) => {
        resp = Utils.manageSuccesResponse(resp);
        const apiResponse = resp.data;
        Utils.setStoredValue('chatBotVersion', apiResponse[1].custom.version);
        Utils.setStoredValue('chatBotDOD', apiResponse[1].custom.date);
      })
      .catch((error) => {
        error = Utils.manageErrorResponse(error);
        console.log(error);
        setLoading(false);
        Utils.setStoredValue('chatBotVersion', CHATBOT_VERSION);
      });
  };

  const ssoVerifyLogin = () => {
    let params = new URLSearchParams(window.location.search);
    let provider = params.get('provider');
    if (provider) {
      let params1 = new URLSearchParams(window.location.hash.substring(1));
      let token = params1.get('access_token');

      loginUser({ username: provider, userPwd: token })
        .then((response) => {
          response = Utils.manageSuccesResponse(response);
          if (response.data.code === 200) {
            Utils.setStoredValue('LoginType', 'SSOLogin');
            props.setLoggedIn(response.data.response);
            if (PROJECT_ID) {
              props.setDomain(PROJECT_ID);
            }

            window.location.href =
              window.location.origin + window.location.pathname;
          } else {
            setErrors([response.data.message]);
            setLoading(false);
          }
        })
        .catch((_error) => {
          _error = Utils.manageErrorResponse(_error);
          setLoading(false);
          setErrors(['Connection Timeout']);
        });
    }
  };

  const getGlobalConfig = () => {
    cohortPreviewExportService.getCohortGlobalConfig().then(
      ({ data }) => {
        if (data.code === 200) {
          Utils.setStoredValue('global', data?.response);
        }else{
          console.error('something went wrong');
        }
      },
      (error) => {
        console.log(error, 'error');
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!username.length || !userPwd.length) {
      setErrors(['Username or password cannot be empty.']);
      return;
    }

    setLoading(true);
    setErrors([]);
    props.resetLoggedIn();
    let payload = { username: username.trim(), userPwd: userPwd };
    if (captchaKey != null || captchaKey != undefined) {
      const captchaValue = recaptcha.current.getValue();
      payload = { ...payload, captchaValue };
    }

    loginUser({ ...payload })
      .then((response) => {
        response = Utils.manageSuccesResponse(response);
        if (response.data.code === 200) {
          setLoading(false);
          const apiResponse = response.data.response;
          props.setLoggedIn(apiResponse);
          Utils.setStoredValue('LoginType', 'UsingPwd');
          getGlobalConfig();
          if (PROJECT_ID) {
            props.setDomain(PROJECT_ID);
          }

          postChatBotMsg();
          document.getElementById('domain').focus();
        } else {
          setErrors([response.data.message]);
          setLoading(false);
        }
      })
      .catch((_error) => {
        _error = Utils.manageErrorResponse(_error);
        setLoading(false);
        setErrors(['Connection Timeout']);
      });
  };

  const domainValue = (val) => {
    props.setDomain(val.target.value);
  };

  return (
    <div className='container-fluid'>
      <div
        className='d-flex flex-column justify-content-center align-items-center'
        style={{
          height: '100vh',
          background: 'linear-gradient(160deg, #fff, #f1f3f4'
        }}
      >
        <div className='d-flex login-modal'>
          <div className='text-center flex-grow-1'>
            <img
              className='img-fluid'
              src={`${URL}image-login-cropped.png`}
              alt='lighthouse'
            />
          </div>
          <div className='d-flex align-item-center justify-content-center bg-white px-4 pt-4 login-content'>
            <div className='text-center px-4 pt-3'>
              <div>
                <img
                  src={`${URL}lighthouse-logo-black.png`}
                  style={{ width: '16rem' }}
                  alt='lighthouse'
                />
                <h6 className='text-muted font-weight-normal mt-2 mb-1'>
                  {ENV_NAME}
                </h6>
                <p className='fs15 text-center text-muted pb-3 app-version'>
                  Version {appVersion}
                </p>
              </div>

              {isLogin ? (
                <form className='needs-validation' noValidate>
                  <TextField
                    className='w-100 mb-4'
                    required
                    size='small'
                    variant='outlined'
                    label='Username'
                    name='username'
                    autoComplete='off'
                    InputLabelProps={{ shrink: true }}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <label htmlFor='userPwd-input' className='d-none'>
                    Password
                  </label>
                  <TextField
                    id='userPwd-input'
                    className='w-100'
                    required
                    size='small'
                    label='Password'
                    name='password'
                    variant='outlined'
                    type={showPassword ? 'text' : 'password'}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setUserPwd(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            className='outline-none toggle-password-visibility'
                            onClick={() => setShowPassword(!showPassword)}
                            data-testid='visibility-btn'
                          >
                            {showPassword ? (
                              <Icon>visibility</Icon>
                            ) : (
                              <Icon>visibility_off</Icon>
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <div className='text-right small mb-3'>
                    <a
                      href={`${API_ROOT}forgetPassword?redirect_uri=${window.location.origin}${URL}`}
                    >
                      Forgot Password?
                    </a>
                    {captchaKey && (
                      <ReCAPTCHA sitekey={captchaKey} ref={recaptcha} />
                    )}
                  </div>
                  {props.loggedIn && (
                    <div id='login1'>
                      <select
                        id='domain'
                        className='form-control mb-3'
                        onChange={(e) => domainValue(e)}
                        data-testid='choose-domain'
                      >
                        <option>Choose Domain</option>
                        <option value='1'>CX</option>
                        <option value='2'>RWE</option>
                        <option value='4'>Test</option>
                      </select>
                    </div>
                  )}
                  <Button
                    type='submit'
                    className={`${
                      loading
                        ? 'bg-light text-muted'
                        : 'bg-primary-blue text-white'
                    } w-100 px-3`}
                    disabled={loading}
                    onClick={(e) => handleSubmit(e)}
                  >
                    {loading ? (
                      <CircularProgress
                        size={25}
                        className='text-muted circular-progress'
                      />
                    ) : (
                      `Sign In`
                    )}
                  </Button>
                  {errors.map((error, i) => (
                    <p className='text-center small text-danger mt-2'>
                      <span key={i}>{error}</span>
                    </p>
                  ))}
                </form>
              ) : (
                <Register />
              )}

              {SSOLogin?.length > 0 && (
                <>
                  <p className='fs14 w-75 text-muted line'>
                    <span>Or sign in with</span>
                  </p>
                  <div className='d-flex justify-content-center login-icon-btn'>
                    {SSOLogin.includes('google') && (
                      <div>
                        <section>
                          <button
                            name='sso-google'
                            onClick={() =>
                              (window.location.href = SSO_URL_GOOGLE)
                            }
                          >
                            <img
                              src={`${URL}Google.png`}
                              width='20'
                              alt='lighthouse'
                            />
                          </button>
                        </section>
                        <label>Google</label>
                      </div>
                    )}
                    {SSOLogin.includes('onLogin') && (
                      <div>
                        <section>
                          <button
                            name='sso-other'
                            onClick={() =>
                              (window.location.href = SSO_URL_OTHER)
                            }
                          >
                            <img
                              src={`${URL}SSO.png`}
                              width='22'
                              alt='lighthouse'
                            />
                          </button>
                        </section>
                        <label>OneLogin</label>
                      </div>
                    )}
                    {SSOLogin.includes('adfs') && (
                      <div>
                        <section>
                          <button
                            name='sso-adfs'
                            onClick={() =>
                              (window.location.href = SSO_URL_ADFS)
                            }
                          >
                            <img
                              src={`${URL}ADFS.png`}
                              width='22'
                              alt='lighthouse'
                            />
                          </button>
                        </section>
                        <label>Incedo ADFS</label>
                      </div>
                    )}
                  </div>
                </>
              )}
              <div className='d-flex justify-content-center align-items-center mt-4'>
                <a
                  className='small mr-2'
                  href={`${URL}pages/terms-conditions.html`}
                  target='_blank' rel="noreferrer"
                >
                  Terms
                </a>{' '}
                |
                <a
                  className='small mx-2'
                  href={`${URL}pages/privacy-policy.html`}
                  target='_blank' rel="noreferrer"
                >
                  Privacy
                </a>{' '}
                |
                <a
                  className='small ml-2'
                  href={`${URL}pages/cookie-policy.html`}
                  target='_blank' rel="noreferrer"
                >
                  Cookie Policy
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-2'>
          <div className='d-flex justify-content-center align-items-center'>
            <span>Supported Browser(s):</span>
            <img
              src={`${URL}google_chrome.png`}
              className='ml-1'
              style={{ width: '3%' }}
              alt='chrome'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Register = () => {
  const { snackBar } = useSnackBar();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [emailId, setEmailId] = useState('');
  const [organization, setOrganization] = useState('');
  const [jobTitle, setJobTitle] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!username.length || !emailId.length) {
      snackBar({
        status: true,
        severity: 'error',
        message: `Full name and email cannot be empty.`
      });
      return;
    }

    setLoading(true);

    const data = {
      emailId: emailId,
      firstName: username.split(' ')[0],
      lastName: username.split(' ')[1],
      organization: organization,
      jobTitle: jobTitle
    };

    registerUser(data)
      .then((response) => {
        response = Utils.manageSuccesResponse(response);
        if (response.data.code === 200) {
          setLoading(false);
          snackBar({
            status: true,
            severity: 'success',
            message: `You have successfully registered with Incedo Lighthouse.\n Please check your email to proceed.`
          });
        } else {
          setLoading(false);
          snackBar({
            status: true,
            severity: 'success',
            message: `${response.data.message}`
          });
        }
      })
      .catch((_error) => {
        _error = Utils.manageErrorResponse(_error);
        setLoading(false);
        snackBar({
          status: true,
          severity: 'error',
          message: `${_error.message}`
        });
      });
  };

  return (
    <form className='needs-validation' noValidate>
      <TextField
        className='w-100 mb-4'
        required
        size='small'
        variant='outlined'
        label='Full Name'
        name='username'
        autoComplete='off'
        InputLabelProps={{ shrink: true }}
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <TextField
        className='w-100 mb-4'
        required
        size='small'
        variant='outlined'
        label='Email'
        name='username'
        autoComplete='off'
        InputLabelProps={{ shrink: true }}
        value={emailId}
        onChange={(e) => setEmailId(e.target.value)}
      />
      <TextField
        className='w-100 mb-4'
        size='small'
        variant='outlined'
        label='Organization'
        name='username'
        autoComplete='off'
        InputLabelProps={{ shrink: true }}
        value={organization}
        onChange={(e) => setOrganization(e.target.value)}
      />
      <TextField
        className='w-100 mb-3'
        size='small'
        variant='outlined'
        label='Job Title'
        name='username'
        autoComplete='off'
        InputLabelProps={{ shrink: true }}
        value={jobTitle}
        onChange={(e) => setJobTitle(e.target.value)}
      />

      <p className='fs13 text-muted mb-2'>
        By clicking Register, you agree to Incedo Lighthouse's Privacy Policy,
        Terms and Cookie Policy.
      </p>

      <Button
        type='submit'
        className={`${
          loading ? 'bg-light text-muted' : 'bg-primary-blue text-white'
        } w-100 px-3`}
        disabled={loading}
        onClick={(e) => handleSubmit(e)}
      >
        {loading ? (
          <CircularProgress
            size={25}
            className='text-muted circular-progress'
          />
        ) : (
          `Register`
        )}
      </Button>
    </form>
  );
};

const openNotification = () => {
  notification.open({
    placement: 'bottomLeft',
    style: { width: '400px' },
    duration: null,
    closeIcon: null,
    className: 'bg-overlay',
    message: <h6>Cookie Consent</h6>,
    description: (
      <div className='align-items-end'>
        <div>
          <p>
            We use cookies to improve your experience while you navigate through
            our platform. The only cookies that we use are categorized as
            necessary cookies that are stored on your browser as they are
            essential for the working of the basic functionalities.
          </p>
          <p>
            View our{' '}
            <a className='small' href={`${URL}pages/cookie-policy.html`}>
              Cookie Policy.
            </a>
          </p>
          <p>
            By clicking "Accept", you consent to our use of cookies. You may
            opt-out by closing your browser tab.
          </p>
        </div>
        <div className='text-right pl-3'>
          <Button
            className='text-nowrap box-shadow-none'
            variant='contained'
            type='info'
            size='small'
            onClick={() => {
              notification.destroy();
              Utils.setStoredValue('clb', true);
            }}
          >
            Accept
          </Button>
        </div>
      </div>
    )
  });
};

export default LoginModal;
