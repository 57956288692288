import React, { useState, useEffect } from 'react';

import 'antd/dist/antd.min.css';
import './App.scss';

import { PROJECT_ID, URL } from './config';
import LoginModal from './modules/LoginPage';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import AppRoutes from './components/AppRoutes';
import ErrorCard from './components/ErrorCard';
import LoginCard from './components/LoginCard';

import { getSSOUser } from './services/authService';
import { MenuProvider } from './contexts/menu';
import { SnackBarProvider } from './contexts/snackbar';
import { KPIProvider } from './contexts/kpi';
import Loader from './utilities/Loader';
import { useTheme } from './hooks';
import Utils from './utilities';
import { CONTEXT_NAME } from './config';
import AccessCard from './components/AccessCard';
import CognitoCard from './components/CognitoCard';
import IdleTimer from './components/IdleTimer';
import { clearIntervelCall, startIntervelCall } from './refresh-token';
import ErrorSnackbar from './components/ErrorSnackbar';

function App() {
  const { isDarkMode } = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [loggedIn, setLogin] = useState(false);
  const [loginData, setLoginData] = useState(null);
  const [domain, setDomain] = useState('');
  const [errors, setErrors] = useState([]);
  const [SSOType, setSSOType] = useState('SSO');
  const [contextLogged, setContextLogged] = useState(URL);

  const setDocumentTitle = () => {
    let title = CONTEXT_NAME ? `${CONTEXT_NAME} - ` : '';
    document.title = `${title}Incedo Lighthouse`;
  };

  useEffect(() => {
    if (loggedIn) {
      startIntervelCall();
    }
  }, [loggedIn]);

  const resetLoggedIn = () => {
    Utils.setStoredValue('resp', '');
    setDomain('');
    setLogin(false);
    setLoginData(null);
    clearIntervelCall();

    if (loggedIn) {
      window.location = URL;
    }
  };

  const showSessionTimeoutModal = () => {
    let elem = document.querySelector('.login-card');
    let cognitoCard = document.querySelector('.cognito-card');
    Utils.setStoredValue('resp', '');
    clearIntervelCall();
    if (elem && Utils.getStoredValue('LoginType') === 'UsingPwd') {
      elem.classList.remove('d-none');
    }
    if (cognitoCard && Utils.getStoredValue('LoginType') === 'SSOLogin') {
      cognitoCard.classList.remove('d-none');
    }
  };

  const setLoggedIn = (response) => {
    Utils.setStoredValue('resp', { isLogged: contextLogged, response });
    setDomain(PROJECT_ID);
    setLogin(true);
    setLoginData(response);
  };

  const handleSSO = () => {
    if (SSOType !== 'SSO') {
      return;
    }
    setErrors([]);
    getSSOUser()
      .then((response) => {
        response = Utils.manageSuccesResponse(response);
        if (response.status === 200) {
          `loginType` in response.data
            ? setSSOType(response.data.loginType)
            : setSSOType('');

          Utils.setStoredValue('version', response.data.version);
          Utils.setStoredValue(
            'captchaSettingKey',
            response.data.captchaKey || null
          );

          if (response.data.loginType === 'SSO') {
            setLoggedIn(response.data);
            if (PROJECT_ID) {
              setDomain(PROJECT_ID);
            }
          }
        } else {
          setErrors([response.data.message]);
        }
      })
      .catch((_error) => {
        _error = Utils.manageErrorResponse(_error);
        setErrors(['Error in Loading Lighthouse Application']);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setLoggedInData = () => {
    const lsData = Utils.getStoredValue('resp');
    if (lsData && `response` in lsData) {
      setLoginData(lsData.response);
      setLogin(lsData.isLogged === contextLogged ? true : false);
      setDomain(lsData.isLogged === contextLogged ? PROJECT_ID : '');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setDocumentTitle();
    setLoggedInData();
    handleSSO();
  }, []);

  if (loggedIn === false || domain < 0) {
    if (errors.length) {
      return (
        <ErrorCard
          title={`Contact Technical Team`}
          detail={`Error in loading Lighthouse application`}
        />
      );
    }
    return isLoading ? (
      <div className='text-center mt-5'>
        <Loader />
      </div>
    ) : (
      <SnackBarProvider>
        <LoginModal
          loggedIn={loggedIn}
          setLoggedIn={setLoggedIn}
          resetLoggedIn={resetLoggedIn}
          setDomain={setDomain}
        />
      </SnackBarProvider>
    );
  }

  return (
    <div className={isDarkMode ? 'theme-dark' : 'theme-default'}>
      <IdleTimer
        showSessionTimeoutModal={showSessionTimeoutModal}
      />
      <SnackBarProvider>
        <Header loginData={loginData} resetLoggedIn={resetLoggedIn} />
        <div className='flyout'>
          <MenuProvider>
            <Sidebar />
            <KPIProvider>
              <main className='main-content'>
                <AppRoutes />
              </main>
            </KPIProvider>
          </MenuProvider>
        </div>
        <LoginCard
          loginData={loginData}
          startRefreshTokenApiCall={startIntervelCall}
          resetLoggedIn={resetLoggedIn}
          contextLogged={contextLogged}
        />
        <AccessCard />
        <CognitoCard loginData={loginData} resetLoggedIn={resetLoggedIn} />
        <ErrorSnackbar/>
      </SnackBarProvider>
    </div>
  );
}

export default App;
